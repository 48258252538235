import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode, sendEmailVerification } from 'firebase/auth';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { colors } from '../lib/theme';
import ContainerBox from '../../shared/ContainerBox';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const auth = getAuth();
      const actionCode = new URLSearchParams(location.search).get('oobCode');

      if (!actionCode) {
        setVerificationStatus('manual');
        return;
      }

      try {
        await applyActionCode(auth, actionCode);
        setVerificationStatus('success');
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('error');
        setErrorMessage(error.message || 'An unknown error occurred.');
      }
    };

    verifyEmail();
  }, [location]);

  const resendVerificationEmail = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await sendEmailVerification(user);
        alert('A new verification email has been sent. Please check your inbox.');
      } catch (error) {
        console.error('Error sending verification email:', error);
        alert('Failed to send verification email. Please try again later.');
      }
    } else {
      alert('No user is currently signed in. Please sign in and try again.');
    }
  };

  return (
    <ContainerBox>
      <Typography variant="h4" gutterBottom>
        Email Verification
      </Typography>
      {verificationStatus === 'verifying' && (
        <CircularProgress sx={{ color: colors.gold }} />
      )}
      {verificationStatus === 'success' && (
        <Box>
          <Typography variant="h6" sx={{ color: 'green', mb: 2 }}>
            Your email has been successfully verified.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/dashboard')}
          >
            Go to Dashboard
          </Button>
        </Box>
      )}
      {verificationStatus === 'error' && (
        <Box>
          <Typography variant="h6" sx={{ color: 'red', mb: 2 }}>
            There was an error verifying your email.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Error details: {errorMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={resendVerificationEmail}
            sx={{ mr: 2 }}
          >
            Resend Verification Email
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/dashboard')}
          >
            Go to Dashboard
          </Button>
        </Box>
      )}
      {verificationStatus === 'manual' && (
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Please check your email for a verification link.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you haven't received the email, you can request a new one.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={resendVerificationEmail}
            sx={{ mr: 2 }}
          >
            Resend Verification Email
          </Button>
        </Box>
      )}
    </ContainerBox>
  );
};

export default VerifyEmail;
